<template>
 <div class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--retro-large">
            <div class="ph-inpage-widget__content ph-inpage-widget-max-310">
                <div class="ph-inpage-widget__row ph-inpage-widget__row_center">
                    <div class="ph-inpage-widget__thumb  ph-inpage-widget__thumb-rounded-4">
                         <img :src="require('@/assets/panel/images/inpage/empty.svg')" v-show="!imgIcon" alt="" />
                         <img :src="imgIcon" v-show="imgIcon" />  
                    </div>
                    <div class="ph-inpage-widget__txt">
                        <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-2">{{adTitle}}</div>
                    </div>
                </div>
                <div class="ph-inpage-widget__desc ph-inpage-widget__txt-overflow-2">{{adText}}</div>
                <div class="ph-inpage-widget__btns-row">
                    <div class="ph-inpage-widget__btns-row-item">
                        <a href="#" class="ph-inpage-widget__btn ph-inpage-widget__btn-ok">ОК</a>
                    </div>
                    <div class="ph-inpage-widget__btns-row-item">
                        <a href="#" class="ph-inpage-widget__btn ph-inpage-widget__btn-cancel">Cancel</a>
                    </div>
                </div>
                <div class="ph-inpage-widget__close ph-inpage-widget__close-border">✕</div>
            </div>
        </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>